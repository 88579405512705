// parcel watch index.js --public-url ./

import "@babel/polyfill";
import React from 'react';
import { createRoot } from "react-dom/client";
import MainRouter from './components/main-router';

// custom css
import './styles/main.css';

// Configure Axios
import axios from 'axios';
axios.defaults.baseURL          = process.env.REACT_APP_API_ENDPOINT;
axios.defaults.withCredentials  = true;
axios.defaults.withXSRFToken    = true;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';
// axios.defaults.xsrfHeaderName   = 'XSRF-TOKEN';

// Log Requests
// axios.interceptors.request.use(request => {
//     console.log('Starting Request', JSON.stringify(request, null, 2))
//     return request
// });
  
// Log Responses
// axios.interceptors.response.use(response => {
//     console.log('Response:', JSON.stringify(response, null, 2))
//     return response
// });

const container = document.getElementById("app");
const root = createRoot(container)
root.render(<MainRouter />);